import React, { useState, useEffect } from "react";

import "./styles.css";

import WordPreview from "../wordpreview/WordPreview";

import debounce from "lodash.debounce";

import { Select, Row, Col, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import wordRequests from "../../../services/helpers/wordquery";
import LocalizationContext from "../../../localization/LocalizationContext";

const { Option } = Select;

export default function SearchWord(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  const { searchWord } = props;

  let [searchWordQueryResult, setSearchWordQueryResult] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    error: null,
  });

  useEffect(() => {
    handleSearch();
  }, [searchWord]);

  const handleSearch = () => {
    if (searchWord.keyword.length === 0) {
      clearResults();
      return;
    }

    setSearchWordQueryResult({
      ...searchWordQueryResult,
      isLoading: true,
      isSuccess: false,
      isError: false,
      data: null,
      error: null,
    });

    let queryData = {
      wordEntry: searchWord.keyword,
    };

    if (searchWord.selectedLanguage === "EN") {
      //
      wordRequests
        .searchEnglishWord(queryData)
        .then((res) => {
          setSearchWordQueryResult({
            ...searchWordQueryResult,
            isLoading: false,
          });
          if (res.itemListElement) {
            setSearchWordQueryResult({
              ...searchWordQueryResult,
              data: res.itemListElement,
              isSuccess: true,
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          setSearchWordQueryResult({
            ...searchWordQueryResult,
            isLoading: false,
            isError: true,
            error: err,
          });
        });
    }
    if (searchWord.selectedLanguage === "NP") {
      //
      wordRequests
        .searchNepaliWord(queryData)
        .then((res) => {
          setSearchWordQueryResult({
            ...searchWordQueryResult,
            isLoading: false,
          });
          if (res.itemListElement) {
            setSearchWordQueryResult({
              ...searchWordQueryResult,
              data: res.itemListElement,
              isSuccess: true,
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          setSearchWordQueryResult({
            ...searchWordQueryResult,
            isLoading: false,
            isError: true,
            error: err,
          });
        });
    }
  };

  const clearResults = () => {
    setSearchWordQueryResult({
      ...searchWordQueryResult,
      isLoading: false,
      isSuccess: false,
      isError: false,
      data: null,
      error: null,
    });
  };

  return (
    <div className="content-container-wrapper">
      <div className="content-container-content">
        {searchWordQueryResult.isLoading && <LoadingSearchResult />}

        {searchWordQueryResult.data && searchWordQueryResult.data.length > 0 && (
          <>
            <DisplaySearchResult
              wordList={searchWordQueryResult.data}
              selectedLanguage={searchWord.selectedLanguage}
              searchKeyword={searchWord.keyword}
            />
          </>
        )}

        {searchWordQueryResult.data &&
          searchWordQueryResult.data.length === 0 && <DisplayNoResultMessage />}

        {searchWord.keyword.length === 0 && <DisaplayIdleMessage />}
      </div>
    </div>
  );
}

function DisplaySearchResult(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  const { wordList, selectedLanguage, searchKeyword } = props;
  return (
    <>
      <div className="displaysearchresult__container">
        <p className="displaysearchresult__search-status-1">
          {t("Selected Language")} :{" "}
          {selectedLanguage === "EN" ? "English" : "Nepali"}
        </p>
        <p className="displaysearchresult__search-status-2">
          {wordList.length} {t("results found for")} : " {searchKeyword} "
        </p>

        {wordList && wordList.length > 0 && (
          <Row>
            {wordList.map((wordItem) => {
              return (
                <Col
                  key={wordItem.identifier}
                  span={24}
                  className="displaysearchresult__col"
                >
                  <WordPreview
                    searchKeyword={searchKeyword}
                    wordInfo={wordItem}
                    searchLanguage={selectedLanguage}
                  />
                </Col>
              );
            })}
          </Row>
        )}
      </div>
    </>
  );
}

function LoadingSearchResult() {
  return (
    <div className="loadingsearchresult__container">
      <Spin size="large" />
    </div>
  );
}

function DisaplayIdleMessage() {
  const { t, i18n } = React.useContext(LocalizationContext);

  return (
    <div className="diaplayidlemessage__container">
      <div className="displayidlemessage__content">
        <div>
          <span className="displatidlemessage__search-icon">
            <SearchOutlined />
          </span>
          <span className="displatidlemessage__main-title">
            {t("Welcome to Sherpa Language App")}
          </span>
        </div>
        <div>{t("Type a word in search bar to search word")}</div>
      </div>
    </div>
  );
}

function DisplayNoResultMessage() {
  const { t, i18n } = React.useContext(LocalizationContext);

  return (
    <div className="diaplayidlemessage__container">
      <div className="displayidlemessage__content">
        <div>
          <span className="displatidlemessage__search-icon">
            <SearchOutlined />
          </span>
          <span className="displatidlemessage__main-title">
            {t("Sorry")} , {t("No Results Found")}
          </span>
        </div>
        <div></div>
      </div>
    </div>
  );
}
