import React, { useState, useEffect } from "react";

import "./styles.css";

import PreviewDefault from "../../../media/images/previewDefaultImg.png";
import ModalWordFeedback from "../modalwordfeedback/ModalWordFeedback";

import { Row, Col, Button } from "antd";
import { CommentOutlined } from "@ant-design/icons";

import Player from "../player/Player";
import LocalizationContext from "../../../localization/LocalizationContext";

import sentenceRequests from "../../../services/helpers/sentencequery";

export default function SentencePreview(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  const { sentenceInfo } = props;

  let [feedbackModal, setFeedBackModal] = useState(false);

  let [sentenceDetails, setSentenceDetails] = useState({
    isLoading: true,

    isFailed: false,
    error: null,

    isSuccess: false,
    data: null,
  });

  useEffect(() => {
    fetchDetailsOfSentence();
  }, [sentenceInfo]);

  let fetchDetailsOfSentence = () => {
    let queryData = {
      identifier: sentenceInfo.identifier,
    };
    sentenceRequests
      .getDetailsOfSentence(queryData)
      .then((res) => {
        if (res) {
          // console.log(res);
          setSentenceDetails({
            ...sentenceDetails,
            isSuccess: true,
            data: res,

            isLoading: false,
          });
        }
      })
      .catch((err) => {
        setSentenceDetails({
          ...sentenceDetails,
          isFailed: true,
          error: err,

          isLoading: false,
        });
      });
  };

  const closeFeedbackModal = () => setFeedBackModal(false);
  const openFeedbackModal = () => setFeedBackModal(true);

  return (
    <div className="sentencepreview__container">
      {sentenceDetails.data && (
        <>
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 20 }}
              xl={{ span: 20 }}
              xxl={{ span: 20 }}
            >
              <div className="sentencepreview__line1-left">
                <p>
                  <span className="color-gray">{t("Sherpa")} :</span>{" "}
                  <span className="sentencepreview__line1-sentence">
                    {sentenceDetails.data.sentenceInSambhota}
                  </span>
                  <span className="sentencepreview__play-icon">
                    {sentenceDetails.data.sentenceAudioInSherpa ? (
                      <Player
                        url={sentenceDetails.data.sentenceAudioInSherpa}
                      />
                    ) : (
                      <Player passive={true} />
                    )}
                  </span>
                  <p className="sentencepreview__line1-sentence-inner">
                    {sentenceDetails.data.sentenceInRoman}
                  </p>
                </p>
                <p>
                  <span className="color-gray">{t("English")} :</span>{" "}
                  <span className="sentencepreview__line1-sentence">
                    {sentenceDetails.data.sentenceInEnglish}
                  </span>
                </p>
                <p>
                  <span className="color-gray">{t("Nepali")} :</span>{" "}
                  <span className="sentencepreview__line1-sentence">
                    {sentenceDetails.data.sentenceInDevanagari}
                  </span>
                </p>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 4 }}
              xl={{ span: 4 }}
              xxl={{ span: 4 }}
            >
              <div className="sentencepreview__line1-right">
                {sentenceDetails.data.sentencePicture ? (
                  <img src={sentenceDetails.data.sentencePicture} />
                ) : (
                  <img src={PreviewDefault} />
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p
                className="wordpreview__comment-report"
                onClick={openFeedbackModal}
              >
                <CommentOutlined /> {t("Feedback")}
              </p>

              {feedbackModal && (
                <ModalWordFeedback
                  modalVisible={feedbackModal}
                  onOk={closeFeedbackModal}
                  onCancel={closeFeedbackModal}
                  //
                  entryName={sentenceDetails.data.sentenceInDevanagari}
                  entryNameCategory={sentenceDetails.data.sentenceCategoryName}
                  entryType="SENTENCE"
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
