import React from "react";

import "./styles.css";

import { Row, Col } from "antd";

export default function Footer() {
  const current = new Date();
  const date = `${current.getFullYear()}`;


  const localConstants = localStorage.getItem('SHERPALANGAPPCONSTANTS') ? JSON.parse(localStorage.getItem('SHERPALANGAPPCONSTANTS')) : {}
  const copyrightText = localConstants.COPYRIGHT_TEXT ? localConstants.COPYRIGHT_TEXT : "(c) - All Rights Reserved"


  return (
    <div className="content-container-wrapper bg-footer">
      <div className="content-container-content">
        <div className="footer__container">
          {/* <Row>
            <Col span={8}>A</Col>
            <Col span={8}>B</Col>
            <Col span={8}>C</Col>
          </Row> */}
          <div className="footer__copyright">
            <p>{date}(c) - All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
}
