import React, { useState, useEffect } from "react";

//
import LocalizationContext from "../../localization/LocalizationContext";

import TopNav from "../components/topnav/TopNav";
import NavBar from "../components/navbar/NavBar";

import Footer from "../components/footer/Footer";
import PromotionBannerOne from "../components/promotionbannerone/PromotionBannerOne";

import SentenceSearchBar from "../components/sentencesearchbar/SentenceSearchBar";
import SentenceListDisplay from "../components/sentencelistdisplay/SentenceListDisplay";

// requests
import sentenceRequests from "../../services/helpers/sentencequery";

export default function Sentence() {
  const { t, i18n } = React.useContext(LocalizationContext);
  let siteLanguage = i18n.language.toUpperCase();

  //
  // search states
  //
  let [sentenceSearchResult, setSentenceSearchResult] = useState({
    isLoading: false,
    isError: false,
    isSuccess: true,
    data: null,
    error: null,
  });
  let [inputText, setInputText] = useState(null);
  let [searchKeyword, setSearchKeyword] = useState(null);
  //
  // states for category
  //
  let [categoryList, setCategoryList] = useState({
    isLoading: true,
    isSuccess: false,
    isError: false,
    error: null,
    data: null,
  });
  let [selectedCategory, setSelectedCategory] = useState(null);
  let [sentenceListByCategory, setSentenceListByCategorty] = useState({
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: null,
    error: null,
  });

  //
  // pagination state
  //
  let [searchPagination, setSearchPagination] = useState({
    start: 0,
    end: 10,

    pageSize: 10,

    total: 10,
    currentPage: 1,
  });

  let [catPagination, setCatPagination] = useState({
    start: 0,
    end: 10,

    pageSize: 10,

    total: 10,
    currentPage: 1,
  });

  //
  // effects for search
  //

  useEffect(() => {
    if (searchKeyword && searchKeyword.length > 0) {
      handleSentenceSearch();
    }
  }, [searchKeyword]);

  // sentence search here
  const handleSentenceSearch = () => {
    // make the  searchquery
    let queryData = {
      keyword: searchKeyword,
      pagination: searchPagination,
    };
    sentenceRequests.searchSentence(queryData).then((res) => {
      if (res.itemListElement) {
        //
        // there is some search result ,
        setSentenceSearchResult({
          ...sentenceSearchResult,
          isLoading: false,
          isSuccess: true,
          data: res.itemListElement,
        });
      }
    });
  };

  //
  // effects and functions for  for category
  //

  //  list all categories
  useEffect(() => {
    let queryData = {
      siteLanguage: siteLanguage,
    };
    sentenceRequests
      .listAvailableSentenceCategory(queryData)
      .then((res) => {
        if (res.availableSentenceCategories.itemListElement) {
          setCategoryList({
            ...categoryList,
            isLoading: false,
            isError: false,
            error: false,
            data: res.availableSentenceCategories.itemListElement,
          });
        }
        // console.log(res);
      })
      .catch((err) => {
        setCategoryList({
          ...categoryList,
          isLoading: false,
          isError: true,
          error: err,
        });
        // console.log(err);
      });
  }, [siteLanguage]);

  // setting default category and getting its list
  useEffect(() => {
    if (categoryList.data && categoryList.data.length > 0) {
      setSelectedCategory(categoryList.data[0]);
    }
  }, [categoryList]);

  //count total number of sentences  in a category
  useEffect(() => {
    if (selectedCategory) {
      let x = {
        categoryName: selectedCategory,
      };
      sentenceRequests.countTotalSentenceByCategory(x).then((res) => {
        if (res.value) {
          // console.log(res);
          setCatPagination({
            ...catPagination,
            total: res.value,
          });
        } else {
          setCatPagination({
            ...catPagination,
          });
        }
      });
    }
  }, [selectedCategory]);

  //list sentences of category  with the pagination
  useEffect(() => {
    if (selectedCategory) {
      setSentenceListByCategorty({
        ...sentenceListByCategory,
        isLoading: true,
      });
      let queryData = {
        pagination: catPagination,
        categoryName: selectedCategory,
      };
      sentenceRequests
        .listSentenceByCategory(queryData)
        .then((res) => {
          if (res.itemListElement) {
            setSentenceListByCategorty({
              ...sentenceListByCategory,
              isLoading: false,
              isSuccess: true,
              data: res.itemListElement,
            });
          }
          // console.log(res);
        })
        .catch((err) => {
          setSentenceListByCategorty({
            ...sentenceListByCategory,
            isLoading: false,
            isError: true,
            error: err,
          });
        });
    }
  }, [selectedCategory, catPagination]);

  function onChangeCategory(value) {
    setSearchKeyword(null);
    setInputText(null);
    setSelectedCategory(value);
  }

  //
  // pagination and stuff
  //

  const handleCatPagination = (pageNumber) => {
    //newpagination values

    let ps = pageNumber * catPagination.pageSize - catPagination.pageSize;
    let pe = pageNumber * catPagination.pageSize;

    setCatPagination({
      ...catPagination,
      start: ps,
      end: pe,

      currentPage: pageNumber,
    });
  };

  const resetCatPagination = () => {
    setCatPagination({
      start: 0,
      end: 10,

      pageSize: 10,

      total: 10,
      currentPage: 1,
    });
  };

  const handleSearchPagination = (pageNumber) => {
    //newpagination values

    let ps = pageNumber * searchPagination.pageSize - searchPagination.pageSize;
    let pe = pageNumber * searchPagination.pageSize;

    setSearchPagination({
      ...searchPagination,
      start: ps,
      end: pe,

      currentPage: pageNumber,
    });
  };

  const resetSearchPagination = () => {
    setSearchPagination({
      start: 0,
      end: 10,

      pageSize: 10,

      total: 10,
      currentPage: 1,
    });
  };

  // clear function
  const clearCategoryFiltering = () => {};
  const resetCategoryFiltering = () => {};

  // logs
  console.log(searchKeyword, sentenceSearchResult.data);
  console.log(selectedCategory, sentenceListByCategory.data);

  return (
    <div>
      <TopNav />
      <NavBar />

      <SentenceSearchBar
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        // these are for search box
        setInputText={setInputText}
        inputText={inputText}
      />

      <SentenceListDisplay
        // sentence search props
        searchKeyword={searchKeyword}
        sentenceSearchResult={sentenceSearchResult}
        // sentence category props
        categoryList={categoryList}
        onChangeCategory={onChangeCategory}
        selectedCategory={selectedCategory}
        sentenceListByCategory={sentenceListByCategory}
        // pagination props
        catPagination={catPagination}
        searchPagination={searchPagination}
        handleCatPagination={handleCatPagination}
        handleSearchPagination={handleSearchPagination}
      />

      <PromotionBannerOne />
      <Footer />
    </div>
  );
}
