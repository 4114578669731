import React, { useState, useEffect, useCallback } from "react";

import "./styles.css";
import debounce from "lodash.debounce";

import { Select, Input, Button, Dropdown, Menu } from "antd";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";

import { Link, withRouter } from "react-router-dom";

function SentenceSearchBar(props) {
  const { searchKeyword, setSearchKeyword, setInputText, inputText } = props;

  const debouncedString = useCallback(
    debounce((nextValue) => setSearchKeyword(nextValue.trim()), 400),
    [] // will be created only once initially
  );

  const handleInputChange = (e) => {
    let keyword = e.target.value;
    setInputText(keyword);

    // setSearchKeyword(keyword);
    // send to debounce and set to state
    debouncedString(keyword);
  };

  return (
    <div className="content-container-wrapper">
      <div className="content-container-content">
        {/* search section  */}
        <div className="sentencesearchbar__container">
          <div className="sentencesearchbar__search-box">
            <div className="sentencesearchbar__search-container">
              <div className="sentencesearchbar__search-input">
                <Input
                  placeholder="Type To search"
                  // defaultValue={searchWord.keyword}
                  value={inputText}
                  // onPressEnter={handleSearch}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(SentenceSearchBar);
