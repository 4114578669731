import React from "react";
import "./styles.css";

import logoLight from "../../../media/images/sherpaDictLogo.png";

import { Row, Col, Button } from "antd";
import { BarsOutlined } from "@ant-design/icons";

import HamMenu from "./HamMenu";

import { Link, withRouter } from "react-router-dom";
import LocalizationContext from "../../../localization/LocalizationContext";

function NavBar(props) {
  const { match } = props;
  const { t, i18n } = React.useContext(LocalizationContext);
  // console.log(i18n.language);

  // const toggleLanguage = () => {};

  return (
    <div className="content-container-wrapper bg-blue">
      <div className="content-container-content">
        {/* <div className="navbar__container"> */}
        <Row className="navbar__container">
          <Col span={1} xs={{span:1}} >
            <div className="navbar__logo">
              <Link to="/">
                <img src={logoLight} />
              </Link>
            </div>
          </Col>

          <Col
            xs={{ span: 20}}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 10 }}
            xl={{ span: 10 }}
            xxl={{ span: 10 }}
            className="navbar__main-title-container">
            <p className="navbar__main-title-l1" >Sherpa Language App</p>
            <p className="navbar__main-title-l2">created by </p>
            <p className="navbar__main-title-l3"  >Global Sherpa Assoiciation</p>
  

          </Col>
              
<div className="navbar__mobilenav-toggle">
                <BarsOutlined
                  className="openbtn"
                  onClick={() => {
                    window.openNav();
                  }}
                />
              </div>
          <Col span={10}>
            <div className="navbar__navitems-container">
              <div className="navbar__navitems">
                <ul>
                  <li className={match.path === "/" ? "active" : null}>
                    <Link to="/">{t("word")}</Link>
                  </li>
                  <li className={match.path === "/sentence" ? "active" : null}>
                    <Link to="/sentence">{t("sentence")}</Link>
                  </li>
                  {/* <li
                    className={
                      match.path === "/transliterate" ? "active" : null
                    }
                  >
                    <Link to="/transliterate">{t("transliterate")}</Link>
                  </li> */}
                </ul>
                <div className="navbar__language-btns">
                  <Button
                    type="primary"
                    onClick={() => i18n.changeLanguage("en")}
                    disabled={i18n.language === "en"}
                  >
                    EN
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => i18n.changeLanguage("np")}
                    disabled={i18n.language === "np"}
                  >
                    NP
                  </Button>
                </div>
              </div>

           
            </div>
          </Col>
          
        </Row>
        {/* </div> */}
        {/* HAM  MENU */}
        <HamMenu />
       
        {/* end  of ham menu */}
      </div>
    </div>
  );
}

export default withRouter(NavBar);
