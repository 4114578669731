import api from "../api";
import to from "await-to-js";

const wordRequests = {
  listSherpaWordsByTag: async (queryData) => {
    let a = {
      "@context": "http://semantro.com/",
      "@type": "DictionarySearch",
      actionName: "listSherpaWordsByTag",
      pageLimit: {
        "@context": "http://semantro.com/",
        "@type": "PageProperty",
        start: 0,
        end: 10,
      },
      data: {
        "@context": "http://semantro.com/",
        "@type": "SherpaWord",
        tag: "first",
      },
    };

    let { data } = a;
    let { pageLimit } = a;

    let queryFormat = {
      ...a,
      data: {
        ...data,
        tag: queryData.tag,
      },
      pageLimit: {
        ...pageLimit,
        start: queryData.pagination.start,
        end: queryData.pagination.end,
      },
    };

    let [error, response] = await to(api.POSTPUBLIC_QUERY(queryFormat));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
  countTotalSherpaWordsByTag: async (queryData) => {
    let a = {
      "@context": "http://semantro.com/",
      "@type": "DictionarySearch",
      actionName: "countTotalSherpaWordsByTag",
      data: {
        "@context": "http://semantro.com/",
        "@type": "SherpaWord",
        tag: "first",
      },
    };

    let { data } = a;

    let queryFormat = {
      ...a,
      data: {
        ...data,
        tag: queryData.tag,
      },
    };

    let [error, response] = await to(api.POSTPUBLIC_QUERY(queryFormat));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },

  getDetailsOfSherpaWord: async (queryData) => {
    let a = {
      "@context": "http://semantro.com/",
      "@type": "DictionarySearch",
      actionName: "getDetailsOfSherpaWord",
      data: {
        "@context": "http://semantro.com/",
        "@type": "SherpaWord",
        identifier: "b650c41c27f04acf-ae832f31dce28cc8",
      },
    };

    let { data } = a;

    let queryFormat = {
      ...a,
      data: {
        ...data,
        identifier: queryData.identifier,
      },
    };

    let [error, response] = await to(api.POSTPUBLIC_QUERY(queryFormat));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
  searchEnglishWord: async (queryData) => {
    let a = {
      "@context": "http://semantro.com/",
      "@type": "DictionarySearch",
      actionName: "searchEnglishWord",
      data: {
        "@context": "http://semantro.com/",
        "@type": "SherpaWord",
        wordEntry: "h",
      },
    };

    let { data } = a;

    let queryFormat = {
      ...a,
      data: {
        ...data,
        wordEntry: queryData.wordEntry,
      },
    };

    let [error, response] = await to(api.POSTPUBLIC_QUERY(queryFormat));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
  searchNepaliWord: async (queryData) => {
    let a = {
      "@context": "http://semantro.com/",
      "@type": "DictionarySearch",
      actionName: "searchNepaliWord",
      data: {
        "@context": "http://semantro.com/",
        "@type": "SherpaWord",
        wordEntry: "gh",
      },
    };

    let { data } = a;

    let queryFormat = {
      ...a,
      data: {
        ...data,
        wordEntry: queryData.wordEntry,
      },
    };

    let [error, response] = await to(api.POSTPUBLIC_QUERY(queryFormat));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
};

export default wordRequests;
