import React, { useState, useEffect } from "react";

import "./styles.css";

import { Select, Row, Col, Pagination } from "antd";

import { SearchOutlined } from "@ant-design/icons";
import SentencePreview from "../sentencepreview/SentencePreview";
import SentencePreviewWithGivenData from "../sentencepreview/SentencePreviewWithGivenData";

import LocalizationContext from "../../../localization/LocalizationContext";

export default function SentenceListDisplay(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  const {
    // sentence search props
    searchKeyword,
    sentenceSearchResult,
    // sentence category props
    categoryList,
    onChangeCategory,
    selectedCategory,
    sentenceListByCategory,
    // pagination props
    catPagination,
    searchPagination,
    handleCatPagination,
    handleSearchPagination,
  } = props;

  // two types , i.e. category and   search
  let [displayContentType, setDisplayContentType] = useState("category");

  useEffect(() => {
    if (searchKeyword) {
      setDisplayContentType("search");
    } else {
      setDisplayContentType("category");
    }
  }, [selectedCategory, searchKeyword]);

  return (
    <div className="content-container-wrapper">
      <div className="content-container-content">
        {/* Layout two Columns left for list of all categories and right for complete listing, constant height   */}

        <Row gutter={16}>
          <Col span={6} className="sentencelistdisplay__cat_section">
            <DisplaySentenceCategoryList
              categoryList={categoryList}
              onChangeCategory={onChangeCategory}
              selectedCategory={selectedCategory}
            />
          </Col>
          <Col span={18} className="sentencelistdisplay__list_section">
            {/* conditional rendering */}
            {/* this is for search  */}
            {searchKeyword &&
              sentenceSearchResult.data &&
              displayContentType === "search" && (
                <DisplaySentenceListOfSearch
                  pagination={searchPagination}
                  handlePagination={handleSearchPagination}
                  sentenceList={sentenceSearchResult}
                  searchKeyword={searchKeyword}
                />
              )}

            {/* this  is for sentence list rendering by  category selection */}
            {selectedCategory &&
              sentenceListByCategory.data &&
              displayContentType === "category" && (
                <DisplaySentenceListOfCategory
                  pagination={catPagination}
                  handlePagination={handleCatPagination}
                  sentenceList={sentenceListByCategory}
                  selectedCategory={selectedCategory}
                />
              )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

function DisplaySentenceListOfCategory(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  const { pagination, handlePagination, sentenceList, selectedCategory } =
    props;
  return (
    <div className="displaysentencelistofcategory__container">
      {sentenceList.data.isLoading && <p>{t("Loading")}...</p>}

      {sentenceList.data && sentenceList.data.length === 0 && (
        <div className="displaysentencelistofcategory__header-err">
          <p>
            {"No Sentences Found In Category"} "{`${selectedCategory}`}"
          </p>
        </div>
      )}

      {sentenceList.data.length > 0 && (
        <>
          <div className="displaysentencelistofcategory__header">
            <p>
              {t("Selected Sentence Category")} :{" "}
              <span className="listbytag__main-title-hl">
                {`${selectedCategory}`}{" "}
              </span>
            </p>
            <Pagination
              defaultCurrent={pagination.page}
              total={pagination.total}
              pageSize={pagination.pageSize}
              onChange={handlePagination}
            />
          </div>
          <Row gutter={24}>
            {sentenceList.data.map((sentence) => {
              return (
                <Col
                  key={sentence.identifier}
                  span={24}
                  className="displaysentencelistofcategory__col"
                >
                  <SentencePreview sentenceInfo={sentence} />
                </Col>
              );
            })}
          </Row>
          <p></p>
          <Pagination
            defaultCurrent={pagination.page}
            total={pagination.total}
            pageSize={pagination.pageSize}
            onChange={handlePagination}
          />
        </>
      )}
    </div>
  );
}

function DisaplayIdleMessage() {
  const { t, i18n } = React.useContext(LocalizationContext);

  return (
    <div className="diaplayidlemessage__container">
      <div className="displayidlemessage__content">
        <div>
          <span className="displatidlemessage__search-icon">
            <SearchOutlined />
          </span>
          <span className="displatidlemessage__main-title">
            {t("Welcome to Sherpa Language App")}
          </span>
        </div>
        <div>
          {t(
            "Please Select a category above to view all sentences in the category"
          )}
        </div>
      </div>
    </div>
  );
}

function DisplaySentenceCategoryList(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  const { categoryList, onChangeCategory, selectedCategory } = props;

  return (
    <div className="displaysentencecategorylist__container">
      <h3 className="displaysentencecategorylist__title">
        {t("Sentence Categories")}:
      </h3>
      <div className="displaysentencecategorylist__list">
        {categoryList &&
          categoryList.data &&
          categoryList.data.map((category) => {
            return (
              <p
                className={
                  selectedCategory === category
                    ? "displaysentencecategorylist__cat-name-selected"
                    : "displaysentencecategorylist__cat-name"
                }
                onClick={() => {
                  onChangeCategory(category);
                }}
                key={category}
              >
                {category}
              </p>
            );
          })}
      </div>
    </div>
  );
}

function DisplaySentenceListOfSearch(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  const { pagination, handlePagination, sentenceList, searchKeyword } = props;
  return (
    <div className="displaysentencelistofcategory__container">
      {sentenceList.data.isLoading && <p>{t("Loading")}...</p>}

      {sentenceList.data && sentenceList.data.length === 0 && (
        <div className="displaysentencelistofcategory__header-err">
          <p>
            {t("No Sentences Found for Keyword")} "{`${searchKeyword}`}"
          </p>
        </div>
      )}

      {sentenceList.data.length > 0 && (
        <>
          <div className="displaysentencelistofcategory__header">
            <p>
              {t("Results For")} :{" "}
              <span className="listbytag__main-title-hl">
                {`${searchKeyword}`}{" "}
              </span>
            </p>
            {/* <div className="displaysentencelistofcategory__pagination">
              <Pagination
                defaultCurrent={pagination.page}
                total={pagination.total}
                pageSize={pagination.pageSize}
                onChange={handlePagination}
              />
            </div> */}
          </div>
          <Row gutter={24}>
            {sentenceList.data.map((sentence) => {
              return (
                <Col
                  key={sentence.identifier}
                  span={24}
                  className="displaysentencelistofcategory__col"
                >
                  <SentencePreviewWithGivenData sentenceData={sentence} />
                </Col>
              );
            })}
          </Row>
          <p></p>
          {/* <Pagination
            defaultCurrent={pagination.page}
            total={pagination.total}
            pageSize={pagination.pageSize}
            onChange={handlePagination}
          /> */}
        </>
      )}
    </div>
  );
}
