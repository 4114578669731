import api from "../api";
import to from "await-to-js";

const variationRequests = {
  getDetailsOfWord: async (queryData) => {
    let a = {
      "@context": "http://semantro.com/",
      "@type": "DictionarySearch",
      actionName: "getDetailsOfWord",
      data: {
        "@context": "http://semantro.com/",
        "@type": "Word",
        wordEntry: "नाङ",
      },
    };
    //
    const { data } = a;
    let queryFormat = {
      ...a,
      data: {
        ...data,
        wordEntry: queryData.wordEntry,
      },
    };
    let [error, response] = await to(api.POSTPUBLIC_QUERY(queryFormat));
    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
};

export default variationRequests;
