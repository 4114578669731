import React from "react";

// import main pages
import Word from "./pages/word/Word";
import Sentence from "./pages/sentence/Sentence";
import Transliterate from "./pages/transliterate/Transliterate";

//localization context
import LocalizationProvider from "./localization/LocalizationProvider";

import { QueryClient, QueryClientProvider } from "react-query";
import { Switch, Route } from "react-router";
import About from "./pages/about/About";
import DonateUs from "./pages/donate/DonateUs";
import Tags from "./pages/components/listbytag/Tags";


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000,
    },
  },
});

// load the app constants from json and dump to local storage 
let getAppConstantsAndSaveToLocal = () => {
  let a = fetch("appConstants.json")
    .then((data) => data.json())
    .catch((error) => console.log(error))
    .then((constants) => {
      console.log(constants)
      localStorage.setItem('SHERPALANGAPPCONSTANTS', JSON.stringify(constants))

    });
};

getAppConstantsAndSaveToLocal()

function App() {
  return (
    <>
      <LocalizationProvider>
        <QueryClientProvider client={queryClient}>
          <div>
            <Switch>
              <Route exact path="/">
                <Word />
              </Route>
              <Route exact path="/tag/:tagName">
                <Word />
              </Route>
              <Route exact path="/about">
                <About />
              </Route>
              <Route exact path="/donate">
                <DonateUs />
              </Route>
              <Route exact path="/sentence">
                <Sentence />
              </Route>
              <Route exact path="/tags/:tagName">
               <Tags/>
              </Route>
              {/* <Route exact path="/transliterate">
                <Transliterate />
              </Route> */}
            </Switch>
          </div>
        </QueryClientProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
