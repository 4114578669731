import React from "react";

import "./styles.css";

import AppStoreAndroid from "../../../media/images/appstore_android.png";
import AppStoreIos from "../../../media/images/appstore_ios.png";

import { Row, Col } from "antd";

export default function PromotionBannerOne() {

  const localConstants = localStorage.getItem('SHERPALANGAPPCONSTANTS') ? JSON.parse(localStorage.getItem('SHERPALANGAPPCONSTANTS')) : {}
  const androidAppUrl = localConstants.ANDROID_APP_URL ? localConstants.ANDROID_APP_URL : "#"
  const iosAppUrl = localConstants.IOS_APP_URL ? localConstants.IOS_APP_URL : "#"

  return (
    <div className="content-container-wrapper bg-one">
      <div className="content-container-content">
        <div className="promotionbannerone__container">
          <Row justify="center" align="middle" gutter={30}>
            {/* <Col className="promotionbannerone__col"></Col> */}
            <Col
              // xs={{ span: 24 }}
              // sm={{ span: 24 }}
              // md={{ span: 24 }}
              lg={{ span: 6 }}
              xl={{ span: 6 }}
              xxl={{ span: 6 }}
            >
              <div className="promotionbannerone__text">
                <p className="promotionbannerone__text-l1">
                  Sherpa Language App{" "}
                </p>
                <p className="promotionbannerone__text-l2">
                  is now available on
                </p>
              </div>
            </Col>
            <Col
              // xs={{ span: 24 }}
              // sm={{ span: 24 }}
              // md={{ span: 24 }}
              lg={{ span: 6 }}
              xl={{ span: 6 }}
              xxl={{ span: 6 }}
            >
              <div className="promotionbannerone__image">
                <a href={`${androidAppUrl}`}>
                  <img src={AppStoreAndroid} />
                </a>
                <a href={`${iosAppUrl}`}>
                  <img src={AppStoreIos} />
                </a>

              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
