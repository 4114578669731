import React, { useState, useEffect } from "react";
import "./styles.css";

import { Modal, Row, Col, Form, Input, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import feedbackRequests from "../../../services/helpers/feedbackquery";

import LocalizationContext from "../../../localization/LocalizationContext";

const { TextArea } = Input;

export default function ModalWordFeedback(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  const { modalVisible, onOk, onCancel } = props;

  let [isLoading, setIsLoading] = useState(false);
  let [error, setError] = useState(null);
  let [data, setData] = useState(null);
  let [isSuccessfull, setIsSuccessfull] = useState(false);

  const handleSubmitFeedback = (e) => {
    setIsLoading(true);

    let entryName = props.entryName;
    let entryType = props.entryType;

    let composedText = props.entryNameCategory ? `${entryName} - ${props.entryNameCategory}` : `${entryName}`

    let queryData = {
      name: e.name,
      educationalUse: e.email,
      alternateName: e.address,
      description: e.description,

      text: composedText,
      learningResourceType: entryType,
    };

    console.log(queryData);
    feedbackRequests
      .addComment(queryData)
      .then((res) => {
        if (res.status === "success") {
          setData(res);
          setIsSuccessfull(true);
        } else {
          setError(res);
        }
      })
      .catch((err) => setError(err));
  };

  return (
    <div>
      <Modal
        visible={modalVisible}
        title={`Feedback`}
        onOk={onOk}
        onCancel={onCancel}
        footer={[]}
        width={`60%`}
      >
        <>
          {error && (
            <p
              style={{
                color: "red",
              }}
            >
              {error.message}
              {"  "}
              {t("Your feedback could not be submitted. Try again at later.")}
            </p>
          )}
          {isSuccessfull ? (
            <>
              <h3>
                {t("Thankyou, Your feedback has been submitted successfully.")}
              </h3>
            </>
          ) : (
            <div>
              <Form
                layout="vertical"
                onFinish={handleSubmitFeedback}
              // ref={}
              >
                {" "}
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      label={t("Name")}
                      name="name"
                      extra=""
                      rules={[
                        {
                          required: true,
                          message: `${t("Please fill this field")}`,
                        },
                      ]}
                    >
                      <Input placeholder={`${t("Your name here")}`} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t("Email")}
                      name="email"
                      extra=""
                      rules={[
                        {
                          required: true,
                          message: `${t("Please fill this field")}`,
                        },
                      ]}
                    >
                      <Input
                        placeholder={`${t("Your email here")}`}
                        type="email"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label={t("Address")}
                      name="address"
                      extra="Street,City,State,Country ( e.g. Imadole, Lalitpur, Nepal )"
                      rules={[
                        {
                          required: true,
                          message: `${t("Please fill this field")}`,
                        },
                      ]}
                    >
                      <Input placeholder={`${t("Complete address here")}`} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label={t("Feedback")}
                      name="description"
                      extra=""
                      rules={[
                        {
                          required: true,
                          message: `${t("Please fill this field")}`,
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        placeholder={`${t("Your description here")}`}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button
                    type="primary"
                    block
                    // icon={<PlusOutlined />}
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {t("Submit")}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
        </>
      </Modal>
    </div>
  );
}
