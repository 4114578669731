import React from "react";

import "./hammenu-styles.css";

import { CloseOutlined } from "@ant-design/icons";
import { Menu, Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import logoLight from "../../../media/images/sherpaDictLogo.png";

const { SubMenu } = Menu;

export default function HamMenu() {
  return (
    <div>
      <div id="mySidepanel" className="sidepanel">
        <div className="content-container-wrapper">
          <div className="content-container-content">
            <div className="sidepanel__header">
              <div className="">
                <Link to="/">
                  <img src={logoLight} />
                </Link>
              </div>
              <div className="mainnavbar__ham-menu-icon">
                {
                  <CloseOutlined
                    className="closebtn"
                    onClick={() => {
                      window.closeNav();
                    }}
                  />
                }
              </div>
            </div>

            <Row>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
              >
                <div className="sidepanel__content">
                  <Menu
                    onClick={() => {}}
                    style={{ width: "100%" }}
                    mode="inline"
                  >
                    <Menu.Item key="1">
                      Word
                      <Link to="/" />
                    </Menu.Item>
                    <Menu.Item key="2">
                      Sentence
                      <Link to="/sentence" />
                    </Menu.Item>
                    {/* <Menu.Item key="3">
                      Transliterate
                      <Link to="/transliterate" />
                    </Menu.Item> */}
                  </Menu>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div></div>
      </div>
    </div>
  );
}
