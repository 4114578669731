import api from "../api";
import to from "await-to-js";

const sentenceRequests = {
  searchSentence: async (queryData) => {
    let a = {
      "@context": "http://semantro.com/",
      "@type": "DictionarySearch",
      actionName: "searchSentence",
      pageLimit: {
        "@context": "http://semantro.com/",
        "@type": "PageProperty",
        start: 0,
        end: 10,
      },
      data: {
        "@context": "http://semantro.com/",
        "@type": "Sentence",
        text: "थुङगु",
      },
    };

    let { data, pageLimit } = a;

    let queryFormat = {
      ...a,
      data: {
        ...data,
        text: queryData.keyword,
      },
      pageLimit: {
        ...pageLimit,
        start: queryData.pagination.start,
        end: queryData.pagination.end,
      },
    };

    let [error, response] = await to(api.POSTPUBLIC_QUERY(queryFormat));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },

  getDetailsOfSentence: async (queryData) => {
    let a = {
      "@context": "http://semantro.com/",
      "@type": "DictionarySearch",
      actionName: "getDetailsOfSentence",
      data: {
        "@context": "http://semantro.com/",
        "@type": "Sentence",
        identifier: "9166f562dce7490e-8283cefc580202bb",
      },
    };

    let { data } = a;

    let queryFormat = {
      ...a,
      data: {
        ...data,
        identifier: queryData.identifier,
      },
    };

    let [error, response] = await to(api.POSTPUBLIC_QUERY(queryFormat));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },

  listSentenceByCategory: async (queryData) => {
    let a = {
      "@context": "http://semantro.com/",
      "@type": "DictionarySearch",
      actionName: "listSentenceByCategory",
      pageLimit: {
        "@context": "http://semantro.com/",
        "@type": "PageProperty",
        start: 0,
        end: 10,
      },
      data: {
        "@context": "http://semantro.com/",
        "@type": "Sentence",
        sentenceCategoryName: "honourable",
      },
    };

    let { data } = a;
    let { pageLimit } = a;

    let queryFormat = {
      ...a,
      pageLimit: {
        ...pageLimit,
        start: queryData.pagination.start,
        end: queryData.pagination.end,
      },
      data: {
        ...data,
        sentenceCategoryName: queryData.categoryName,
      },
    };

    let [error, response] = await to(api.POSTPUBLIC_QUERY(queryFormat));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },

  countTotalSentenceByCategory: async (queryData) => {
    let a = {
      "@context": "http://semantro.com/",
      "@type": "DictionarySearch",
      actionName: "countTotalSentenceByCategory",
      data: {
        "@context": "http://semantro.com/",
        "@type": "Sentence",
        sentenceCategoryName: "respect",
      },
    };

    let { data } = a;

    let queryFormat = {
      ...a,

      data: {
        ...data,
        sentenceCategoryName: queryData.categoryName,
      },
    };

    let [error, response] = await to(api.POSTPUBLIC_QUERY(queryFormat));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },

  listAvailableSentenceCategory: async (queryData) => {
    let a = {
      "@context": "http://semantro.com/",
      "@type": "DictionarySearch",
      actionName: "listAvailableSentenceCategory",
      data: {
        "@context": "http://semantro.com/",
        "@type": "SentenceCategory",
        description: "EN",
      },
    };

    let { data } = a;
    let queryFormat = {
      ...a,
      data: {
        ...data,
        description: queryData.siteLanguage,
      },
    };

    let [error, response] = await to(api.POSTPUBLIC_QUERY(queryFormat));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
};

export default sentenceRequests;
