import React, { useState, useEffect } from "react";

import "./styles.css";

import { Select, Input, Button, Dropdown, Menu, Row, Col, Spin, Pagination } from "antd";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import PreviewDefault from "../../../media/images/previewDefaultImg.png";
import ModalWordFeedback from "../modalwordfeedback/ModalWordFeedback";
import VariationPreview from "../variationpreview/VariationPreview";
import { CommentOutlined } from "@ant-design/icons";

import Player from "../player/Player";

import LocalizationContext from "../../../localization/LocalizationContext";

import wordRequests from "../../../services/helpers/wordquery";
import { Link, withRouter } from "react-router-dom";

const { Option } = Select;

function ListByTag(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  let tagName = props.match.params.tagName;

  // let [selectedLanguage, setSelectedLanguage] = useState("EN");
  let [searchKeyword, setSearchKeyword] = useState(tagName);

  let [searchWordQueryResult, setSearchWordQueryResult] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    error: null,
  });
  let [pagination, setPagination] = useState({
    start: 0,
    end: 10,
    pageSize: 10,
    total: 10,
    currentPage: 1,
  });

  useEffect(() => {
    handleSearch();
  }, [searchKeyword]);

  const handleSearch = () => {
    setSearchWordQueryResult({
      ...searchWordQueryResult,
      isLoading: true,
      isSuccess: false,
      isError: false,
      data: null,
      error: null,
    });

    let queryData = {
      tag: searchKeyword,
      pagination: pagination,
    };

    wordRequests
      .listSherpaWordsByTag(queryData)
      .then((res) => {
        setSearchWordQueryResult({
          ...searchWordQueryResult,
          isLoading: false,
        });
        if (res.itemListElement) {
          setSearchWordQueryResult({
            ...searchWordQueryResult,
            data: res.itemListElement,
            isSuccess: true,
            isLoading: false,
            
          });
         
        }
      })
      .catch((err) => {
        setSearchWordQueryResult({
          ...searchWordQueryResult,
          isLoading: false,
          isError: true,
          error: err,
        });
      });
  };

  const handlePagination = (pageNumber) => {
    //newpagination values

    let ps = pageNumber * pagination.pageSize - pagination.pageSize;
    let pe = pageNumber * pagination.pageSize;

    setPagination({
      ...pagination,
      start: ps,
      end: pe,

      currentPage: pageNumber,
    });
  };


  return (
    <div className="content-container-wrapper">
      <div className="content-container-content">
        <div className="listbytag__main-title">
          <h1>
            {`${t("Words with tag")}`}:{" "}
            <span className="listbytag__main-title-hl">{searchKeyword} </span>
            {
              console.log(searchKeyword)
            }
          </h1>
        </div>

        {searchWordQueryResult.isLoading && <LoadingSearchResult />}

        {searchWordQueryResult.data &&
          searchWordQueryResult.data.length > 0 && (
            <>



              <div>
                <Pagination
                  defaultCurrent={pagination.page}
                  total={pagination.total}
                  pageSize={pagination.pageSize}
                  onChange={handlePagination}
                />
              </div>

              <DisplaySearchResult wordList={searchWordQueryResult.data} />
               
              <div style={{ textAlign: "center", marginBottom: "20px" }} >
                <Pagination
                  defaultCurrent={pagination.page}
                  total={pagination.total}
                  pageSize={pagination.pageSize}
                  onChange={handlePagination}
                />
              </div>

            </>

          )}

        {searchWordQueryResult.data &&
          searchWordQueryResult.data.length === 0 && <DisplayNoResultMessage />}
      </div>
    </div>
  );
}

function DisplaySearchResult(props) {
  const { wordList } = props;
  return (
    <>
      <div className="displaysearchresult__container">
        {wordList && wordList.length > 0 && (
          <Row>
            {wordList.map((wordItem) => {
              return (
                <Col
                  key={wordItem.identifier}
                  span={24}
                  className="displaysearchresult__col"
                >
                  <WordPreviewForTag wordInfo={wordItem} searchLanguage="EN" />
                </Col>
              );
            })}
          </Row>
        )}
      </div>
    </>
  );
}

function LoadingSearchResult() {
  const { t, i18n } = React.useContext(LocalizationContext);

  return (
    <div className="loadingsearchresult__container">
      <Spin size="large" />
    </div>
  );
}

function DisplayNoResultMessage() {
  const { t, i18n } = React.useContext(LocalizationContext);

  return (
    <div className="diaplayidlemessage__container">
      <div className="displayidlemessage__content">
        <div>
          <span className="displatidlemessage__search-icon">
            <SearchOutlined />
          </span>
          <span className="displatidlemessage__main-title">
            {t("Sorry")} , {t("No Results Found")}.
          </span>
        </div>
        <div></div>
      </div>
    </div>
  );
}

function WordPreviewForTag(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  const { wordInfo, searchLanguage } = props;

  let [wordFeedbackModal, setWordFeedBackModal] = useState(false);

  const closeWordFeedbackModal = () => setWordFeedBackModal(false);
  const openWordFeedbackModal = () => setWordFeedBackModal(true);

  return (
    <>
      {wordInfo && (
        <div className={`wordpreview__container`}>
          <Row>
            <Col span={24}>
              <div className="wordpreview__title-row">
                <p className="wordpreview__title">
                  {searchLanguage === "EN"
                    ? `${wordInfo.wordEntryInEnglish}`
                    : `${wordInfo.wordEntryInDevanagari}`}
                </p>
                <span className="wordpreview__pos">
                  [
                  {wordInfo.pos &&
                    wordInfo.pos.itemListElement.length > 0 &&
                    wordInfo.pos.itemListElement.map((item) => (
                      <span key={item}>{item} </span>
                    ))}
                  ]
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 18 }}
              xl={{ span: 18 }}
              xxl={{ span: 18 }}
              className="wordpreview__details__left"
            >
              {/* <div className="wordpreview__details_definition">
              <span className="wordpreview__details_definition-title">
                Meaning:
              </span>
              <span className="wordpreview__details_definition-text">
                {wordInfo.wordMeaningInDevanagari
                  ? wordInfo.wordMeaningInDevanagari
                  : "N/A"}
              </span>
            </div> */}

              <div className="wordpreview__details__translations">
                {/* <p className="wordpreview__details__translations-title">
                translations
              </p> */}

                <div className="wordpreview__details__translation-row">
                  <span className="wordpreview__details__translation-row-title">
                    {t("Sherpa")}:
                  </span>
                  <div className="wordpreview__details__translation-row-detail">
                    <p
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {wordInfo.wordEntryInRomanizedSherpa && (
                        <p className="wordpreview__detail-meaning-hl">
                          {wordInfo.wordEntryInRomanizedSherpa}{" "}
                        </p>
                      )}
                      {wordInfo.wordEntryInDevanagariSherpa && (
                        <p className="wordpreview__detail-meaning-hl">
                          {wordInfo.wordEntryInDevanagariSherpa}
                        </p>
                      )}
                      {wordInfo.sambhotaSherpaScript && (
                        <p className="wordpreview__detail-meaning-hl">
                          {wordInfo.sambhotaSherpaScript}
                        </p>
                      )}
                      {wordInfo.ipa && (
                        <p className="wordpreview__detail-meaning-hl">
                          / {wordInfo.ipa} /
                        </p>
                      )}

                      <span className="wordpreview__play-icon">
                        {wordInfo.wordAudioInSambhota ? (
                          <Player url={wordInfo.wordAudioInSambhota} />
                        ) : (
                          <Player passive={true} />
                        )}
                      </span>
                    </p>
                    {/* <div>
                    <span className="color-maroon">meaning :</span>{" "}
                    {wordInfo.wordMeaningInDevanagari
                      ? wordInfo.wordMeaningInDevanagari
                      : "N/A"}
                  </div> */}

                    <div className="flex-row">
                      <span className="color-maroon">{t("Variations")} :</span>{" "}
                      <div className="mar-l-10 flex-col">
                        {wordInfo.variationInSambhota &&
                          wordInfo.variationInSambhota.itemListElement.length > 0
                          ? wordInfo.variationInSambhota.itemListElement.map(
                            (item) => (
                              <VariationPreview
                                variationKeyword={item}
                                key={item}
                              />
                            )
                          )
                          : t("N/A")}
                      </div>
                    </div>
                    <div className="flex-row">
                      <span className="color-maroon">{t("Honorific")} :</span>{" "}
                      <div className="mar-l-10 flex-col">
                        {wordInfo.exampleUsage &&
                          wordInfo.exampleUsage.itemListElement.length > 0
                          ? wordInfo.exampleUsage.itemListElement.map(
                            (item) => (
                              <VariationPreview
                                variationKeyword={item}
                                key={item}
                              />
                            )
                          )
                          : t("N/A")}
                      </div>
                    </div>
                  </div>
                </div>

                {/*  */}
                <div className="wordpreview__details_definition">
                  <span className="wordpreview__details_definition-title color-maroon">
                    {t("Meaning")}:
                  </span>
                  <span className="wordpreview__details_definition-text">
                    {wordInfo.wordMeaningInDevanagari
                      ? wordInfo.wordMeaningInDevanagari
                      : t("N/A")}
                  </span>
                </div>

                {/* <div className="wordpreview__details__translations-details"> */}
                <div className="wordpreview__details__translation-row">
                  <span className="wordpreview__details__translation-row-title">
                    {t("English")}:
                  </span>
                  <span className="wordpreview__details__translation-row-detail">
                    <p className="wordpreview__detail-meaning-hl">
                      {wordInfo.wordEntryInEnglish
                        ? wordInfo.wordEntryInEnglish
                        : t("N/A")}
                    </p>
                  </span>
                </div>

                <div className="wordpreview__details__translation-row">
                  <span className="wordpreview__details__translation-row-title">
                    {t("Nepali")}:
                  </span>
                  <span className="wordpreview__details__translation-row-detail">
                    <p className="wordpreview__detail-meaning-hl">
                      {wordInfo.wordEntryInDevanagari
                        ? wordInfo.wordEntryInDevanagari
                        : t("N/A")}
                    </p>
                  </span>
                </div>
                {
                  wordInfo.pos &&
                  wordInfo.pos.itemListElement.length > 0 &&
                  wordInfo.pos.itemListElement.map(
                    (item) => {

                      if (item === "verb") {
                        //this item is verb and render this
                        return (
                          <div className="wordpreview__details__translation-rowx">
                            <span className="wordpreview__details__translation-row-title">
                              {t("Verb Form")}:
                            </span>
                            <div className="wordpreview__details__translation-row-detail">
                              <p className="wordpreview__detail-meaning-hl-">
                                {
                                  wordInfo.definition &&
                                    wordInfo.definition.itemListElement.length > 0 ?
                                    wordInfo.definition.itemListElement.map(item => { return (<p>{item}</p>) })
                                    : t("N/A")
                                }
                              </p>
                            </div>
                          </div>)
                      }
                    }
                  )
                }

                {/*  */}
                {/* end   */}
                {/* </div> */}
              </div>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 6 }}
              xl={{ span: 6 }}
              xxl={{ span: 6 }}
            >
              <div className="wordpreview__details__rightimg">
                {wordInfo.wordPicture ? (
                  <img src={wordInfo.wordPicture} />
                ) : (
                  <img src={PreviewDefault} />
                )}
              </div>
            </Col>
          </Row>
          <p className="wordpreview__tag-title">
            {t("Explore more words with similar tags")}:
          </p>
          <div className="wordpreview__tag-list ">
            {wordInfo.tag &&
              wordInfo.tag.itemListElement &&
              wordInfo.tag.itemListElement.length > 0 &&
              wordInfo.tag.itemListElement.map((item) => {
                return (
                  <>
                    <Link to={`/tag/${item}`} key={item}>
                      <p className="wordpreview__tag-item">{item}</p>
                    </Link>
                  </>
                );
              })}
          </div>
          <div>
            <Row>
              <Col span={24}>
                <p
                  className="wordpreview__comment-report"
                  onClick={openWordFeedbackModal}
                >
                  <CommentOutlined /> {t("Feedback")}
                </p>

                {wordFeedbackModal && (
                  <ModalWordFeedback
                    modalVisible={wordFeedbackModal}
                    onOk={closeWordFeedbackModal}
                    onCancel={closeWordFeedbackModal}
                    //
                    entryName={
                      wordInfo.wordEntryInEnglish
                        ? wordInfo.wordEntryInEnglish
                        : ""
                    }
                    entryType="WORD"
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
}

export default withRouter(ListByTag);
