import api from "../api";
import to from "await-to-js";

const feedbackRequests = {
  addComment: async (queryData) => {
    //
    let a = {
      "@context": "http://semantro.com/",
      "@type": "DictionaryMutation",
      actionName: "addComment",
      data: {
        "@context": "http://semantro.com/",
        "@type": "Comment",
        name: "Sachin Giri",
        text: "wrong spelling word",
        educationalUse: "sachingiri619@gmail.com",
        alternateName: "dhobighat , lalitpur",
        description: "khangba",
        learningResourceType: "WORD",
      },
    };
    //
    //
    const { data } = a;
    let queryFormat = {
      ...a,
      data: {
        ...data,
        name: queryData.name,
        text: queryData.text,
        educationalUse: queryData.educationalUse,
        alternateName: queryData.alternateName,
        description: queryData.description,
        learningResourceType: queryData.learningResourceType,
      },
    };
    let [error, response] = await to(api.MUTATION_QUERY(queryFormat));
    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
};

export default feedbackRequests;
