import React, { useState, useEffect } from "react";

import "./styles.css";

import PreviewDefault from "../../../media/images/previewDefaultImg.png";
import ModalWordFeedback from "../modalwordfeedback/ModalWordFeedback";

import { Row, Col, Button } from "antd";
import { CommentOutlined } from "@ant-design/icons";

import Player from "../player/Player";

import LocalizationContext from "../../../localization/LocalizationContext";

import sentenceRequests from "../../../services/helpers/sentencequery";

export default function SentencePreviewWithGivenData(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  const { sentenceData } = props;

  let [feedbackModal, setFeedBackModal] = useState(false);

  const closeFeedbackModal = () => setFeedBackModal(false);
  const openFeedbackModal = () => setFeedBackModal(true);

  return (
    <div className="sentencepreview__container">
      {sentenceData && (
        <>
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 20 }}
              xl={{ span: 20 }}
              xxl={{ span: 20 }}
            >
              <div className="sentencepreview__line1-left">
                <p>
                  <span className="color-gray">{t("Sherpa")} :</span>{" "}
                  <span className="sentencepreview__line1-sentence">
                    {sentenceData.sentenceInSambhota}
                  </span>
                  <span className="sentencepreview__play-icon">
                    {sentenceData.sentenceAudioInSherpa ? (
                      <Player url={sentenceData.sentenceAudioInSherpa} />
                    ) : (
                      <Player passive={true} />
                    )}
                  </span>
                  <p className="sentencepreview__line1-sentence-inner">
                    {sentenceData.sentenceInRoman}
                  </p>
                </p>
                <p>
                  <span className="color-gray">{t("English")} :</span>{" "}
                  <span className="sentencepreview__line1-sentence">
                    {sentenceData.sentenceInEnglish}
                  </span>
                </p>
                <p>
                  <span className="color-gray">{t("Nepali")} :</span>{" "}
                  <span className="sentencepreview__line1-sentence">
                    {sentenceData.sentenceInDevanagari}
                  </span>
                </p>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 4 }}
              xl={{ span: 4 }}
              xxl={{ span: 4 }}
            >
              <div className="sentencepreview__line1-right">
                {sentenceData.sentencePicture ? (
                  <img src={sentenceData.sentencePicture} />
                ) : (
                  <img src={PreviewDefault} />
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p
                className="wordpreview__comment-report"
                onClick={openFeedbackModal}
              >
                <CommentOutlined /> {t("Feedback")}
              </p>

              {feedbackModal && (
                <ModalWordFeedback
                  modalVisible={feedbackModal}
                  onOk={closeFeedbackModal}
                  onCancel={closeFeedbackModal}
                  //
                  entryName={sentenceData.sentenceInDevanagari}
                  entryType="SENTENCE"
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
