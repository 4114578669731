import React, { Suspense } from "react";
import ReactDOM from "react-dom";

//router  setup
import { HashRouter as Router } from "react-router-dom";

// import all styles , load theme style first
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "./styles/theme.css";
import "./styles/custom-antd.css";
import "./styles/index.css";

import App from "./App";

import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <Router>
    <Suspense fallback={<div>Loading</div>}>
      <App />
    </Suspense>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
