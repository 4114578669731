import React from "react";

import "./styles.css";

import NavBar from "../components/navbar/NavBar";
import Footer from "../components/footer/Footer";
import PromotionBannerOne from "../components/promotionbannerone/PromotionBannerOne";
import TopNav from "../components/topnav/TopNav";

export default function DonateUs() {
  return (
    <div>
      <TopNav />
      <NavBar />

      <DonateContent />

      <PromotionBannerOne />
      <Footer />
    </div>
  );
}

function DonateContent() {
  return (
    <p className="donate__container">
      this is donate page content yet to come{" "}
    </p>
  );
}
