import React, { useState, useEffect } from "react";

import NavBar from "../components/navbar/NavBar";
import SearchWord from "../components/searchword/SearchWord";
import WordSearchBar from "../components/wordsearchbar/WordSearchBar";
import Footer from "../components/footer/Footer";
import PromotionBannerOne from "../components/promotionbannerone/PromotionBannerOne";
import TopNav from "../components/topnav/TopNav";
import ListByTag from "../components/listbytag/ListByTag";

import { Link, withRouter } from "react-router-dom";

function Word(props) {
  let tagName = props.match.params.tagName;
console.log(tagName)
  let [searchWord, setSearchWord] = useState({
    selectedLanguage: "EN",
    keyword: "House",
  });

  let [currentWordListing, setCurrentWordListing] = useState("word");

  useEffect(() => {
    if (tagName) {
      setCurrentWordListing("tag");
    } else {
      setCurrentWordListing("word");
    }
  }, [tagName]);

  useEffect(() => {
    setCurrentWordListing("word");
    props.history.push("/");
  }, [searchWord]);
console.log(currentWordListing)
console.log(searchWord)
  // some kind  of mechanism to se

  return (
    <div>
      <TopNav />
      <NavBar />

      <WordSearchBar setSearchWord={setSearchWord} searchWord={searchWord} />

      {currentWordListing === "word" && <SearchWord searchWord={searchWord} />}
      {currentWordListing === "tag" && <ListByTag />}

      <PromotionBannerOne />
      <Footer />
    </div>
  );
}

export default withRouter(Word);
