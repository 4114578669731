import axios from "axios";
import to from "await-to-js";

let getApiEndpoint = async () => {
  let a = await fetch("apiConstants.json")
    .then((data) => data.json())
    .catch((error) => console.log(error))
    .then((config) => {
      const endpoints = { ...config };
      return endpoints;
      // console.log(endpoints);
    });
  return a;
};

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log("interceptor error", error);
    if (error.response && error.response.status === 401) {
      console.log("401 error", error.response.data);
      throw error.response.data;
    }
    if (error.response && error.response.status === 400) {
      console.log("400 error", error.response.data);
      throw error.response.data;
    }
    if (error.response && error.response.status === 404) {
      console.log("404 error", error.response.data);
      throw error.response.data;
    }
    if (error.response && error.response.status === 177) {
      console.log("177 error", error.response.data);
      throw error.response.data;
    }
    throw error;
  }
);

const api = {
  // public query
  POSTPUBLIC_QUERY: async (resource) => {
    let { QUERY_END_POINT } = await getApiEndpoint();
   console.log(QUERY_END_POINT)
    const requestData = new FormData();
    requestData.append("data", JSON.stringify(resource));

    try {
      return await axios({
        method: "POST",
        url: QUERY_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          // language: "en"
        },
        data: requestData,
      });
    } catch (error) {
      throw await error;
    }
  },
  MUTATION_QUERY: async (resource) => {
    let { MUTATION_END_POINT } = await getApiEndpoint();
   console.log(MUTATION_END_POINT)
    const requestData = new FormData();
    requestData.append("data", JSON.stringify(resource));

    try {
      return await axios({
        method: "POST",
        url: MUTATION_END_POINT,
        headers: {
          "Content-Type": "multipart/form-data",
          // language: "en"
        },
        data: requestData,
      });
    } catch (error) {
      throw await error;
    }
  },

};

export default api;
