import React, { useEffect, useState, useRef } from "react";

import "./styles.css";

import PreviewDefault from "../../../media/images/previewDefaultImg.png";

import ModalWordFeedback from "../modalwordfeedback/ModalWordFeedback";
import VariationPreview from "../variationpreview/VariationPreview";
import { Row, Col, Button } from "antd";
import { CommentOutlined } from "@ant-design/icons";

import Player from "../player/Player";

import { Link } from "react-router-dom";

import wordRequests from "../../../services/helpers/wordquery";
import LocalizationContext from "../../../localization/LocalizationContext";

export default function WordPreview(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  const { wordInfo, searchLanguage } = props;

  let [wordDetailQueryResult, setWordDetailQueryResult] = useState({
    isLoading: true,
    isSuccess: false,
    isError: false,
    data: null,
    error: null,
  });
  let [wordFeedbackModal, setWordFeedBackModal] = useState(false);

  const closeWordFeedbackModal = () => setWordFeedBackModal(false);
  const openWordFeedbackModal = () => setWordFeedBackModal(true);

  useEffect(() => {
    let queryData = {
      identifier: wordInfo.identifier,
    };

    wordRequests
      .getDetailsOfSherpaWord(queryData)
      .then((res) => {
        setWordDetailQueryResult({
          ...wordDetailQueryResult,
          isLoading: false,
        });
        if (res) {
          setWordDetailQueryResult({
            ...wordDetailQueryResult,
            data: res,
            isSuccess: true,
          });
        }
      })
      .catch((err) => {
        setWordDetailQueryResult({
          ...wordDetailQueryResult,
          isLoading: false,
          isError: true,
          error: err,
        });
      });
  }, []);

  return (
    <>
      {wordDetailQueryResult.data && (
        <div className={`wordpreview__container`}>
          <Row>
            <Col span={24}>
              <div className="wordpreview__title-row">
                <p className="wordpreview__title">
                  {searchLanguage === "EN"
                    ? `${wordDetailQueryResult.data.wordEntryInEnglish}`
                    : `${wordDetailQueryResult.data.wordEntryInDevanagari}`}
                </p>
                <span className="wordpreview__pos">
                  [
                  {wordDetailQueryResult.data.pos &&
                    wordDetailQueryResult.data.pos.itemListElement.length > 0 &&
                    wordDetailQueryResult.data.pos.itemListElement.map(
                      (item) => <span key={item}>{item} </span>
                    )}
                  ]
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 18 }}
              xl={{ span: 18 }}
              xxl={{ span: 18 }}
              className="wordpreview__details__left"
            >
              {/* <div className="wordpreview__details_definition">
                <span className="wordpreview__details_definition-title">
                  Meaning:
                </span>
                <span className="wordpreview__details_definition-text">
                  {wordDetailQueryResult.data.wordMeaningInDevanagari
                    ? wordDetailQueryResult.data.wordMeaningInDevanagari
                    : "N/A"}
                </span>
              </div> */}

              <div className="wordpreview__details__translations">
                {/* <p className="wordpreview__details__translations-title">
                  translations
                </p> */}

                <div className="wordpreview__details__translation-row">
                  <span className="wordpreview__details__translation-row-title">
                    {t("Sherpa")}:
                  </span>
                  <div className="wordpreview__details__translation-row-detail">
                    <p
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {wordDetailQueryResult.data
                        .wordEntryInRomanizedSherpa && (
                          <p className="wordpreview__detail-meaning-hl">
                            {
                              wordDetailQueryResult.data
                                .wordEntryInRomanizedSherpa
                            }{" "}
                          </p>
                        )}
                      {wordDetailQueryResult.data
                        .wordEntryInDevanagariSherpa && (
                          <p className="wordpreview__detail-meaning-hl">
                            {
                              wordDetailQueryResult.data
                                .wordEntryInDevanagariSherpa
                            }
                          </p>
                        )}
                      {wordDetailQueryResult.data.sambhotaSherpaScript && (
                        <p className="wordpreview__detail-meaning-hl">
                          {wordDetailQueryResult.data.sambhotaSherpaScript}
                        </p>
                      )}
                      {wordDetailQueryResult.data.ipa && (
                        <p className="wordpreview__detail-meaning-hl">
                          / {wordDetailQueryResult.data.ipa} /
                        </p>
                      )}

                      <span className="wordpreview__play-icon">
                        {wordDetailQueryResult.data.wordAudioInSambhota ? (
                          <Player
                            url={wordDetailQueryResult.data.wordAudioInSambhota}
                          />
                        ) : (
                          <Player passive={true} />
                        )}
                      </span>
                    </p>
                    {/* <div>
                      <span className="color-maroon">meaning :</span>{" "}
                      {wordDetailQueryResult.data.wordMeaningInDevanagari
                        ? wordDetailQueryResult.data.wordMeaningInDevanagari
                        : "N/A"}
                    </div> */}

                    <div className="flex-row">
                      <span className="color-maroon">{t("Variations")} :</span>{" "}
                      <div className="mar-l-10 flex-col">
                        {wordDetailQueryResult.data.variationInSambhota &&
                          wordDetailQueryResult.data.variationInSambhota
                            .itemListElement.length > 0
                          ? wordDetailQueryResult.data.variationInSambhota.itemListElement.map(
                            (item) => (
                              <VariationPreview
                                variationKeyword={item}
                                key={item}
                              />
                            )
                          )
                          : t("N/A")}
                      </div>
                    </div>
                    <div className="flex-row">
                      <span className="color-maroon">{t("Honorific")} :</span>{" "}
                      <div className="mar-l-10 flex-col">
                        {wordDetailQueryResult.data.exampleUsage &&
                          wordDetailQueryResult.data.exampleUsage.itemListElement
                            .length > 0
                          ? wordDetailQueryResult.data.exampleUsage.itemListElement.map(
                            (item) => (
                              <VariationPreview
                                variationKeyword={item}
                                key={item}
                              />
                            )
                          )
                          : t("N/A")}
                      </div>
                    </div>
                  </div>
                </div>

                {/*  */}
                <div className="wordpreview__details_definition">
                  <span className="wordpreview__details_definition-title color-maroon">
                    {t("Meaning")}:
                  </span>
                  <span className="wordpreview__details_definition-text">
                    {wordDetailQueryResult.data.wordMeaningInDevanagari
                      ? wordDetailQueryResult.data.wordMeaningInDevanagari
                      : t("N/A")}
                  </span>
                </div>

                {/* <div className="wordpreview__details__translations-details"> */}
                <div className="wordpreview__details__translation-row">
                  <span className="wordpreview__details__translation-row-title">
                    {t("English")}:
                  </span>
                  <span className="wordpreview__details__translation-row-detail">
                    <p className="wordpreview__detail-meaning-hl">
                      {wordDetailQueryResult.data.wordEntryInEnglish
                        ? wordDetailQueryResult.data.wordEntryInEnglish
                        : t("N/A")}
                    </p>
                  </span>
                </div>

                <div className="wordpreview__details__translation-row">
                  <span className="wordpreview__details__translation-row-title">
                    {t("Nepali")}:
                  </span>
                  <span className="wordpreview__details__translation-row-detail">
                    <p className="wordpreview__detail-meaning-hl">
                      {wordDetailQueryResult.data.wordEntryInDevanagari
                        ? wordDetailQueryResult.data.wordEntryInDevanagari
                        : t("N/A")}
                    </p>
                  </span>
                </div>

                {
                  wordDetailQueryResult.data.pos &&
                  wordDetailQueryResult.data.pos.itemListElement.length > 0 &&
                  wordDetailQueryResult.data.pos.itemListElement.map(
                    (item) => {

                      if (item === "verb") {
                        //this item is verb and render this
                        return (
                          <div className="wordpreview__details__translation-rowx">
                            <span className="wordpreview__details__translation-row-title">
                              {t("Verb Form")}:
                            </span>
                            <div className="wordpreview__details__translation-row-detail">
                              <p className="wordpreview__detail-meaning-hl-">
                                {
                                  wordDetailQueryResult.data.definition &&
                                    wordDetailQueryResult.data.definition.itemListElement.length > 0 ?
                                    wordDetailQueryResult.data.definition.itemListElement.map(item => { return (<p>{item}</p>) })
                                    : t("N/A")
                                }
                              </p>
                            </div>
                          </div>)
                      }
                    }
                  )
                }


                {/*  */}
                {/* end   */}
                {/* </div> */}
              </div>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 6 }}
              xl={{ span: 6 }}
              xxl={{ span: 6 }}
            >
              <div className="wordpreview__details__rightimg">
                {wordDetailQueryResult.data.wordPicture ? (
                  <img src={wordDetailQueryResult.data.wordPicture} />
                ) : (
                  <img src={PreviewDefault} />
                )}
              </div>
            </Col>
          </Row>
          <p className="wordpreview__tag-title">
            {t("Explore more words with similar tags")}:
          </p>
          <div className="wordpreview__tag-list ">
            {wordDetailQueryResult.data.tag &&
              wordDetailQueryResult.data.tag.itemListElement &&
              wordDetailQueryResult.data.tag.itemListElement.length > 0 &&
              wordDetailQueryResult.data.tag.itemListElement.map((item) => {
                return (
                  <>
                    <Link to={`/tag/${item}`} key={item}>
                      <p className="wordpreview__tag-item">{item}</p>
                    </Link>
                  </>
                );
              })}
          </div>
          <div>
            <Row>
              <Col span={24}>
                <p
                  className="wordpreview__comment-report"
                  onClick={openWordFeedbackModal}
                >
                  <CommentOutlined /> {t("Feedback")}
                </p>

                {wordFeedbackModal && (
                  <ModalWordFeedback
                    modalVisible={wordFeedbackModal}
                    onOk={closeWordFeedbackModal}
                    onCancel={closeWordFeedbackModal}
                    //
                    entryName={
                      wordDetailQueryResult.data.wordEntryInEnglish
                        ? wordDetailQueryResult.data.wordEntryInEnglish
                        : ""
                    }
                    entryType="WORD"
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
}
