import React, { useState, useEffect } from "react";

import "./styles.css";

import WordPreview from "../wordpreview/WordPreview";

import { Select, Input, Button, Dropdown, Menu, Row, Col } from "antd";
import { CopyOutlined, SearchOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;

export default function SearchTransliterate() {
  let [searchKeyword, setSearchKeyword] = useState(null);

  return (
    <div className="content-container-wrapper">
      <div className="content-container-content">
        {/* search section  */}
        <div className="searchtransliterate__container">
          <div className="searchtransliterate__search-box">
            <div className="searchtransliterate__search-container">
              <div className="searchtransliterate__search-input">
                <Input
                  placeholder="Type To search"
                  //   onPressEnter={handleSearch}
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                />
              </div>
              <div className="searchtransliterate__search-button">
                <Button
                // onClick={handleSearch}
                >
                  <SearchOutlined />
                  Transliterate
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* display search result  section */}
        <DisplayTransliterateResult />
      </div>
    </div>
  );
}

function DisplayTransliterateResult() {
  return (
    <div className="displaytransliterateresult__container">
      <div className="displaytransliterateresult__header">
        <p>Transliterate Results</p>
      </div>
      <div className="displaytransliterateresult__results">
        <div className="displaytransliterateresult__result">
          <div className="displaytransliterateresult__result-top">
            <p>Nepali</p>
            <p>
              <CopyOutlined /> Copy
            </p>
          </div>

          <TextArea rows={2} />
        </div>
        <div className="displaytransliterateresult__result">
          <div className="displaytransliterateresult__result-top">
            <p>Devanagiri</p>
            <p>
              <CopyOutlined /> Copy
            </p>
          </div>
          <TextArea rows={2} />
        </div>
        <div className="displaytransliterateresult__result">
          <div className="displaytransliterateresult__result-top">
            <p>Sambhota</p>
            <p>
              <CopyOutlined /> Copy
            </p>
          </div>
          <TextArea rows={2} />
        </div>
      </div>
    </div>
  );
}
