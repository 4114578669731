import React from "react";
import { Row, Col } from 'antd';
import "./styles.css";

import TopNav from "../components/topnav/TopNav";
import NavBar from "../components/navbar/NavBar";
import Footer from "../components/footer/Footer";
import PromotionBannerOne from "../components/promotionbannerone/PromotionBannerOne";

import { InfoCircleOutlined } from "@ant-design/icons";
import ReactPlayer from "react-player";
import Logo from '../../media/images/sherpaDictLogo.png';
import Global from  '../../media/images/newlogo.png';
export default function About() {

  return (
    <div>
      <TopNav />
      <NavBar />

      <AboutContent />

      <PromotionBannerOne />
      <Footer />
    </div>
  );
}

function AboutContent() {
  const localConstants = localStorage.getItem('SHERPALANGAPPCONSTANTS') ? JSON.parse(localStorage.getItem('SHERPALANGAPPCONSTANTS')) : {}
  const howToUseUrl = localConstants.HOW_TO_USE_VIDEO_URL ? localConstants.HOW_TO_USE_VIDEO_URL : "#"


  return (
    <div className="about__container_wrapper">
      <div className="about__container_content">
        <Row style={{marginBottom:'14px'}}>
          <Col  style={{marginLeft:'25%'}} >
            
          <img src={Global} style={{width:'100%', height:'100px',marginTop:'14%'}}></img>
        
          </Col>
          <Col xs={10} >
          <h1 className="about__container_content-title" style={{color:'#C0504D',marginTop:'15%',textDecoration:'underline',fontWeight:'bold'}}>ABOUT SHERPA LANGUAGE APP</h1>
          </Col>
          <Col>
          <img src={Logo} style={{width:'100%' , height:'100px',marginTop:'17px'}} ></img>
          </Col>
  
        </Row>
        {/* <div className="about__container_content-title">
                <div>
                <img src={Global} style={{width:'100%', height:'100px'}}></img>
                </div>
                <div>
                <h1 className="about__container_content-title">ABOUT SHERPA LANGUAGE APP</h1>
                </div>
                <div>
                <img src={Logo} style={{width:'100%' , height:'120px'}} ></img>
                </div>
        </div>
        */}

        <p>Language is more than just a tool for communication, especially for marginalized indigenous communities. It serves as our link to our cultural heritage and historical and ecological knowledge. It is a vehicle of our value systems and an important marker of our identity.</p>

        <p>There are around 7139 living languages worldwide, according to Ethnologue, and majority of them are under threat due to powerful national and global languages. UNESCO estimates that about 90% of the world’s languages may be extinct by the end of this century.
        </p>

        <p>
          UNESCO’s <i>Atlas of the World’s Languages in Danger </i> has listed Sherpa as a vulnerable language. However, it would be safer to say that it falls under the category of <b>“definitely endangered”</b> which is defined as the situation where “children no longer learn the language as mother tongue in the home”. This is especially true for Sherpa children growing outside of their native villages.
        </p>

        <p>Technology has played a crucial role in limiting our language use to dominant languages. The internet may never be Sherpa friendly, but we can certainly use technology to reverse the trend of language loss.</p>

        <p><b>The Sherpa Language App</b> was created under the leadership of the Global Sherpa Association and formally launched on October 8, 2021. Using an app to learn Sherpa is a convenient and effective method to promote Sherpa language, especially among young Sherpas, who live in various geographical locations. </p>

        <p>Teaching children Sherpa is crucial for the preservation of our language. Hence, we plan to put regular effort in order to make learning Sherpa language children friendly by producing cartoon, games and learning materials.
        </p>

        <p>We hope that this App will be useful in promoting and preserving Sherpa language and strengthening our cultural identity.
        </p>

        <h2> How to use this App?</h2>

        <ul>
          <li><InfoCircleOutlined /><p>You can type any word either in English, Devanagari, Nepali Unicode, or Sambota script and find out its Sherpa translation in all three scripts and an audio for exact pronunciation.</p>
          </li>
          <li><InfoCircleOutlined /><p>You can type whole sentences in English or Devanagari and see it’s Sherpa translation in both scripts and an audio recording. You can also browse through various sentence categories to learn more.</p></li>
          <li>
            <InfoCircleOutlined /><p>Under each word entry there is a space for comments and suggestions. Please let us know if our use of any term is mistaken or can be improved.</p>
          </li>
          <li>
            <ReactPlayer url={howToUseUrl} width={"100%"} height={500} />
          </li>
        </ul>
      </div>
    </div>
  );
}
