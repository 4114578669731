import React, { useState, useEffect, useCallback } from "react";

import "./styles.css";
import debounce from "lodash.debounce";
import wordRequests from "../../../services/helpers/wordquery";
import { Select, Input, Button, Dropdown, Menu } from "antd";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import ListByTag from "../listbytag/ListByTag";
import { Link, withRouter } from "react-router-dom";
import LocalizationContext from "../../../localization/LocalizationContext";
import WordPreview from "../wordpreview/WordPreview";
import ListTransliterate from "../listTransliterate/ListTransliterate";

function WordSearchBar(props) {
  const { t, i18n } = React.useContext(LocalizationContext);
const[tag,setTag]=useState("")

  const { searchWord, setSearchWord } = props;

  let tagName = props.match.params.tagName;
  let [searchKeyword, setSearchKeyword] = useState(tagName);
  
  
console.log(searchKeyword)


console.log(tagName)

  const menu = (
    <Menu>
      <Menu.Item key="EN" onClick={(e) => handleSearchLanguageSwitch(e.key)}>
        {"ENGLISH"}
      </Menu.Item>
      <Menu.Item key="NP" onClick={(e) => handleSearchLanguageSwitch(e.key)}>
        {"NEPALI"}
      </Menu.Item>
      {/* <Menu.Item key="TAG" onClick={(e) => handleSearchLanguageSwitch(e.key)}>
        {"TAG"}
      </Menu.Item> */}
    </Menu>
  );
  

  // const handleTag=()=>{
    
  //   props.history.match("/tags")
  //     }
  

  const handleSearchLanguageSwitch = (key) => {

    if (searchKeyword === 'House' || searchKeyword === 'घर') {
      if (key === "EN") {
        setSearchWord({
          selectedLanguage: key,
          keyword: "House",
        });
      } 
      else if(key==="NP")  {
        setSearchWord({
          selectedLanguage: key,
          keyword: "घर",
        });
      }
      else if(key==="TAG") 
      {
        setSearchWord({
          selectedLanguage: key,
          keyword: "Shelters",
        });
      }
     
    
     
    } 

    else {
      if (key === "EN") {
        setSearchWord({
          selectedLanguage: key,
          keyword: searchKeyword,
        });
      } else if(key==="NP")   {
        setSearchWord({
          selectedLanguage: key,
          keyword: searchKeyword,
        });
      }
      else if(key==="TAG")  {
        setSearchWord({
          selectedLanguage: key,
          keyword: searchKeyword,
        });
   
      }
    
    }

  };
 console.log(searchWord.selectedLanguage)
 

  const debouncedString = useCallback(
    debounce(
      (nextValue, language) =>
        // (nextValue) =>
        setSearchWord({
          selectedLanguage: language,
          keyword: nextValue,
        }),
      400
    ),
    [] // will be created only once initially
  );

  // useEffect(() => {
  //   checkWordLanguage();
  //   debouncedString(searchKeyword);
  // }, [searchKeyword, selectedLanguage]);

  // const initiateSearch = () => {
  //   checkWordLanguage();
  //   debouncedString(searchKeyword);
  // };

  // const handleSearchLanguageSwitch = (key) => {
  //   setSelectedLanguage(key);
  //   if (key === "EN") {
  //     setSearchKeyword("House");
  //   } else {
  //     setSearchKeyword("घर");
  //   }
  // };

   
  useEffect(() => {
    setSearchKeyword(searchWord.keyword);
    
  }, [searchWord.keyword]);

  // later removed 
  // 
  // this function checks if the word is in unicode nepali or english and automatically switches 
  const checkWordLanguage = (word) => {
    // if (word.length < 1) word = "";
    // let decValueOfFirstCharacter = word.charCodeAt(0);
    //
    // the range of devangiri unicode is from 0900 to 097F in hex
    // that is 2304 to 2431 in decimal
    //
    // if (decValueOfFirstCharacter >= 2304 && decValueOfFirstCharacter <= 2341) {
    //   debouncedString(word.trim(), "NP");
    // } else {
    //   debouncedString(word.trim(), "EN");
    // }

    // 
    // 
    // debouncedString(word);
  };

  const handleInputChange = (e) => {
    let word = e.target.value;
    // local component state
    setSearchKeyword(word);

    // check  language and send for  debounce
    // checkWordLanguage(word);
    debouncedString(word, searchWord.selectedLanguage);

  };
console.log(searchKeyword)
  return (
 
    <div className="content-container-wrapper">
      <div className="content-container-content">
        {/* search section  */}
        <div className="searchword__container">
          <div className="searchword__search-box">
            <div className="searchword__search-container">
              <div className="searchword__search-selectbutton">
                <Dropdown overlay={menu}>
                  <Button>
                    <span className="searchword__search-selectbutton-text">
                      {searchWord.selectedLanguage === "EN"
                        ? "ENGLISH"
                        : "NEPALI"
                        }
                     
                      {"  "}
                 
                    </span>
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
              <div className="searchword__search-input">
                <Input
                  placeholder={t("Type To search")}
                  // defaultValue={searchWord.keyword}
                  value={searchKeyword}
                  // onPressEnter={handleSearch}
                  onChange={handleInputChange}
                />
              </div>
              <div className="searchword__search-button">
            

               
             
               
                 

           </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(WordSearchBar);
