import React from "react";
import "./styles.css";

import logoLight from "../../../media/images/sherpaDictLogo.png";

import { Row, Col, Button } from "antd";
import { Link, withRouter } from "react-router-dom";
import LocalizationContext from "../../../localization/LocalizationContext";

function TopNav(props) {
  const { match } = props;
  const { t, i18n } = React.useContext(LocalizationContext);
  // console.log(i18n.language);

  // const toggleLanguage = () => {};

  const localConstants = localStorage.getItem('SHERPALANGAPPCONSTANTS') ? JSON.parse(localStorage.getItem('SHERPALANGAPPCONSTANTS')) : {}
  const topNavNewsText = localConstants.TOP_NAV_NEWS_TEXT ? localConstants.TOP_NAV_NEWS_TEXT : ""


  return (
    <div className="content-container-wrapper bg-topnav">
      <div className="content-container-content">
        {/* <div className="topnav__container"> */}
        <Row className="topnav__container">
          <Col span={12}>
            <p style={{ textTransform: "uppercase", lineHeight: "35px", fontWeight: "bold" }}>
              {topNavNewsText}
            </p>
          </Col>
          <Col span={12}>
            <div className="topnav__navitems">
              <ul>
                <li>
                  <Link to="/about">{t("About Us")}</Link>
                </li>
                <li>
                  <Link to="/donate">{t("Donate Us")}</Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        {/* </div> */}
      </div>
    </div>
  );
}

export default withRouter(TopNav);
